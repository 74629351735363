

import { format, parseISO } from "date-fns";
import { Vue, Component } from "vue-property-decorator";

import { ReportService } from "@/services/report";
import { ReportDTO } from "@/services/report/report.dto";

@Component
export default class Report extends Vue {
  reports: ReportDTO[] = [];
  loading = false;
  headers = [
    {
      text: "Name",
      value: "name"
    },
    {
      text: "Type",
      value: "type"
    },
    {
      text: "Date Of Report",
      value: "date"
    },
    {
      text: "",
      value: "action"
    }
  ];

  data() {
    return {
      loading: false
    };
  }

  created() {
    this.$store.commit("liveData/setSelectedActivity", 4);
    this.$store.commit("liveData/setSelectedActivityName", "Reports");

    this.getReportsFromServer();
  }

  async getReportsFromServer() {
    this.loading = true;
    try {
      this.reports = await ReportService.getReports();
      this.reports.forEach((report: ReportDTO) => {
        report.date = parseISO(report.date.toString());
      });
    } catch (err) {
      console.log(err);
    }
    this.loading = false;
  }

  formatDate(date: Date): string {
    return format(date, "dd-MM-yyyy HH:mm");
  }

  openReportLink(link: string): void {
    if (link) {
      window.open(link, "_blank");
    }
  }
}
